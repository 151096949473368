import { NumberInputOnChange } from '@tb-core/components/behavior/form-controls/number-input';
import Currency from '@tb-core/components/simple/currency';
import TextButton from '@tb-core/components/styled/buttons/text-button';
import StandardNumberInput from '@tb-core/components/styled/form-controls/standard-number-input';
import { CSCItemModifierType } from '@tb-core/constants/client-side-cart';
import { ModifierLabel } from '@tb-core/constants/products';
import {
    ADD_MODIFIER_REGEX,
    MODIFIED_MODIFIER_REGEX,
    PROTEIN_OPTION_REGEX,
    STYLE_MODIFIER_REGEX
} from '@tb-core/constants/regex';
import { interpolate } from '@tb-core/helpers/interpolate';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import useTooltipContext from '@tb-core/hooks/use-tooltip-context';
import {
    CSCItem,
    CSCItemModifier,
    CSCItemTemplate
} from '@tb-core/types/client-side-cart';

import styles from '../styles.module.scss';

interface MiniCartLineItemProps {
    item: CSCItemTemplate;
    itemWasRemovedLabel: string;
    modifiedLabel: string;
    removeLabel: string;
    updateCart?: (cartItem: CSCItem) => void;
}

// Copy to display in Confirm Modal
const ConfirmRemoveItemPrompt = ({ prompt = '' }: { prompt?: string }) => (
    <span dangerouslySetInnerHTML={{ __html: prompt }} />
);

const MiniCartLineItem = ({
    item,
    itemWasRemovedLabel,
    removeLabel,
    updateCart
}: MiniCartLineItemProps) => {
    const { updateModals } = useLayoutActivityContext();
    const { setMiniCartHeaderText } = useTooltipContext();

    const onUpdateQuantity: NumberInputOnChange = (newQty?: number) => {
        if (newQty && updateCart) {
            updateCart({
                ...item,
                qty: newQty
            });
        }
    };

    const onDeleteItem = () => {
        const miniCartHeaderText = interpolate(itemWasRemovedLabel, {
            product: item?.itemName
        });

        if (item && updateCart) {
            setMiniCartHeaderText(miniCartHeaderText);
            updateCart({
                ...item,
                qty: 0
            });
        }
    };

    /**
     * Returns CartItem modifiers in arrays grouped by modifier type.
     *
     * @param {Object} CSCItemTemplate the product in cart
     * @return {Array[]} 2D array with grouped by matching modifiers
     */

    // @TODO consolidate function with 'renderModifierGroups' in
    // tb-purchase/src/components/cart/styled/cart-item/cart-item-detailed.tsx
    const groupModifiersByType = (product: CSCItemTemplate) => {
        if (product.modifiers.length === 0) {
            return [];
        }

        const groupedModifierArray = product.modifiers.reduce(
            (acc: Record<string, CSCItemModifier[]>, obj) => {
                const isModified =
                    (ADD_MODIFIER_REGEX.test(obj.modifierType) &&
                        PROTEIN_OPTION_REGEX.test(obj.opt)) ||
                    MODIFIED_MODIFIER_REGEX.test(obj.modifierType);
                const key = isModified
                    ? CSCItemModifierType.MODIFIED
                    : obj.modifierType;

                // If modifier type is 'STYLE' we do not want to add the
                // modifier text so just return the previous acc object
                if (STYLE_MODIFIER_REGEX.test(obj.modifierType)) {
                    return acc;
                }

                // If there is no key with the current modifier type,
                // add a new key pair to the object
                if (!acc[key]) {
                    acc[key] = [];
                }

                // If an item modifierType is 'ADD' and the opt is `proteinOption`,
                // update the modifierType to 'Modified'. This will only reflect on the modifier
                // text visually and not affect what gets sent to the cart.
                if (isModified) {
                    obj.modifierType = CSCItemModifierType.MODIFIED;
                }

                acc[key].push(obj);

                return acc;
            },
            {}
        );

        return Object.values(groupedModifierArray);
    };

    const confirmRemoveHandler = () => {
        const confirmRemovePrompt = `Remove <strong>${item?.itemName}</strong> from your bag?`;

        updateModals({
            ['confirm-modal']: {
                callback: onDeleteItem,
                close: true,
                description: (
                    <ConfirmRemoveItemPrompt prompt={confirmRemovePrompt} />
                ),
                textCancel: 'No',
                textConfirm: 'Yes'
            }
        });
    };

    const renderComboItems = (comboItems: CSCItem[]) => (
        <ul className={styles['combo-list-items']}>
            {comboItems.map((item, i) => (
                <li key={`${item?.itemName}-${i}`}>
                    {item?.itemName}

                    {item?.modifiers &&
                        item.modifiers.length > 0 &&
                        renderModifiers(item as CSCItemTemplate)}
                </li>
            ))}
        </ul>
    );

    const renderModifiers = (item: CSCItemTemplate) => {
        let modifiedLabel = '';

        return groupModifiersByType(item).map((value: unknown) => {
            const modifier = value as CSCItemModifier[];

            switch (modifier[0].modifierType) {
                case 'ADD':
                    modifiedLabel = ModifierLabel.ADDED;
                    break;
                case 'MINUS':
                    modifiedLabel = ModifierLabel.REMOVED;
                    break;
                default:
                    modifiedLabel = ModifierLabel.MODIFIED;
                    break;
            }
            return (
                modifiedLabel && (
                    <>
                        <p className={styles['modified-text']}>
                            {modifiedLabel}
                        </p>
                        {modifier.length > 0 && renderModifiersList(modifier)}
                    </>
                )
            );
        });
    };

    const renderModifiersList = (modifiers: CSCItemModifier[]) => (
        <ul className={styles['modifier-list-items']}>
            {modifiers &&
                modifiers.map((modifier, i) => (
                    <li key={`${modifier?.name}-${i}`}>{modifier.name}</li>
                ))}
        </ul>
    );

    return (
        <li className={styles['mini-cart-list-item']}>
            <div className={styles['mini-cart-content']}>
                <img
                    className={styles.thumbnail}
                    src={item?.image}
                    alt={item.itemName}
                />
                <StandardNumberInput
                    aria-label="Mini Cart Item Quantity"
                    className={styles.quantity}
                    max={50}
                    min={1}
                    onChange={onUpdateQuantity}
                    value={item.qty}
                />
                <div>
                    <p className={styles['product-name']}>{item.itemName}</p>

                    {item?.items?.length > 0 && renderComboItems(item.items)}

                    {item?.modifiers?.length > 0 && renderModifiers(item)}

                    <TextButton onClick={confirmRemoveHandler}>
                        {removeLabel}
                    </TextButton>
                </div>
            </div>

            <Currency
                className={styles.currency}
                qty={item.qty}
                total={item.price}
            />
        </li>
    );
};

export default MiniCartLineItem;
