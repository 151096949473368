/*
 * Convert a given string into capitalized string
 * @param {string} str the string to be converted
 * @returns the formatted string
 */

export const capitalize = (str: string = '') =>
    str.charAt(0).toUpperCase() + str.slice(1);

/*
 * Convert a given string into title case
 * @param {string} str the string to be converted
 * @returns the formatted string
 */
export const formatTitleCase = (str: string = '') =>
    str
        .toLowerCase() // convert all characters to lower case
        .split(' ') // separate the words
        // loop through the words and capitalize the first letter
        .map((w): string => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' '); // join the words back together

/*
 * Strips a given string value of special characters
 * @param {string} value the string to be converted
 * @returns the formatted value
 */
export const stripString = (value: string) => value.replace(/[^A-Z0-9]/gi, '');

/*
 * Replaces string hyphens with spaces and capitalizes each word
 * @param {str} kebab cased string to be converted
 * @returns the formatted value
 */
export const kebabToTitleCase = (str: string) =>
    str
        .split('-')
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(' ');
