import { datadogLogs } from '@datadog/browser-logs';

import { webFixedVersion } from '@tb-core/helpers/next-env';

export const dataDogInitialize = (dataDogEnv: string) => {
    const dataDogClientToken = 'pubd163eb16c21e04e40d65523e7860bbcd';
    const sampleRate = ['perftest', 'prod'].indexOf(dataDogEnv) < 0 ? 100 : 15;

    datadogLogs.init({
        clientToken: dataDogClientToken,
        env: dataDogEnv,
        forwardErrorsToLogs: true,
        sampleRate,
        service: 'app-web',
        silentMultipleInit: true,
        site: 'datadoghq.com',
        trackSessionAcrossSubdomains: true,
        useCrossSiteSessionCookie: true,
        useSecureSessionCookie: true,
        version: webFixedVersion
    });
    datadogLogs.addLoggerGlobalContext('project', 'app-web-2');
    datadogLogs.logger.setLevel('warn');
};
