import {
    CSCItemModifierOptions,
    CSCItemModifierType
} from '@tb-core/constants/client-side-cart';
import { getModifierSuffix } from '@tb-core/helpers/products/cart';
import { getVariant } from '@tb-core/helpers/products/variant';
import { RealObject } from '@tb-core/types';
import {
    CSCItem,
    CSCItemModifier,
    CSCModifierDetail
} from '@tb-core/types/client-side-cart';
import {
    CartSummaryProduct,
    CustomizationOption,
    CustomizationOptions,
    Product,
    StyleOption,
    VariantOption
} from '@tb-core/types/products';

type OptionMapTypes =
    | CustomizationOption[]
    | StyleOption
    | string[]
    | undefined;

/**
 * Grab all possible plus needed to gather extended product information.
 * @param products an array of cart items wih limited details.
 * @returns String[]
 */
export const addAllProductCodes = (products: CartSummaryProduct[]) => {
    const productCodes: string[] = [];

    products.forEach((product: CartSummaryProduct) => {
        if (!productCodes.includes(product.plu)) {
            productCodes.push(product.plu);
        }
        product.items?.forEach((productItem: CSCItem) => {
            if (!productCodes.includes(productItem.plu)) {
                productCodes.push(productItem.plu);
            }
        });
    });

    return productCodes.sort((a: string, b: string) => (a < b ? -1 : 1));
};

export const getCustomizationOptions = (
    options: CustomizationOptions,
    optionName: string
) => {
    if (!options) {
        return undefined;
    }

    const optionMap: Record<string, OptionMapTypes> = {
        [CSCItemModifierOptions.ADDON]: options.addonOptions,
        [CSCItemModifierOptions.FRESCO_ADD]: options.frescoAdd,
        [CSCItemModifierOptions.FRESCO_PRODUCT]: options.frescoProduct,
        [CSCItemModifierOptions.FRESCO_REMOVE]: options.frescoRemove,
        [CSCItemModifierOptions.GRILLABLE_PRODUCT]:
            options.grilled || options.grillableProduct,
        [CSCItemModifierOptions.INCLUDE]: options.includeOptions,
        [CSCItemModifierOptions.PROTEIN]: options.proteinOptions,
        [CSCItemModifierOptions.SAUCE]: options.sauceOptions,
        [CSCItemModifierOptions.SUPREME_ADD]: options.supremeAdd,
        [CSCItemModifierOptions.SUPREME_PRODUCT]: options.supremeProduct,
        [CSCItemModifierOptions.SUPREME_REMOVE]: options.supremeRemove,
        [CSCItemModifierOptions.UPGRADE]: options.upgradeOptions,
        [CSCItemModifierOptions.VEGETARIAN_ADD]: options.vegetarianAdd,
        [CSCItemModifierOptions.VEGETARIAN_PRODUCT]: options.vegetarianProduct,
        [CSCItemModifierOptions.VEGETARIAN_REMOVE]: options.vegetarianRemove
    };

    return optionMap[optionName];
};

/**
 * getItemDetails()
 * get extended combo item information.
 * TODO: Very Difficult Code to follow, feel free to add more:
 * Comments / Examples / Type Definitions / Refactoring...
 *
 * @param itemsArr  from IndexedDB: has an array of items in combo, contains: groupCode, plu, modifiers
 * @param onTheSideLabel text label
 * @param pdpProductJson array of each Product/item found in entire order ( not just this combo ).
 * @param product combo Product JSON containing productGroups[].swapList[]
 * @returns CSCItemDetail[]
 */
export const getItemDetails = (
    itemsArr: CSCItem[],
    onTheSideLabel: string = '',
    pdpProductJson: Product[],
    product: Product | undefined
) =>
    itemsArr
        ? itemsArr.map((item: CSCItem) => {
              /**
               * Get the item group to compare against.
               */
              const matchingGroup = product?.productGroups?.find(
                  (productGroup: RealObject) =>
                      productGroup.groupID === item.groupCode
              );

              /**
               * The full product will be the detailed product.
               */
              let fullProduct: Product | undefined;
              let itemPrice = 0;
              let isAvailable = false;
              const plu = item.plu;

              /** Ensure we match the variant code, not just the main code.
               *
               */
              const pluMatch = matchingGroup?.swapList?.find(swapItem => {
                  if (swapItem.variantOptions) {
                      return swapItem.variantOptions.find(variantOption => {
                          if (variantOption.code === plu) {
                              itemPrice = variantOption.priceData.value;
                              isAvailable =
                                  variantOption.purchasable &&
                                  variantOption.availableInStore;
                              return true;
                          }
                          return false;
                      });
                  } else if (swapItem.code === plu) {
                      itemPrice = swapItem.price.value;
                      isAvailable =
                          swapItem.isAvailableInStore && swapItem.purchasable;
                      return true;
                  }
                  return false;
              });

              if (pluMatch) {
                  fullProduct = pdpProductJson.find(product =>
                      pluMatch.variantOptions
                          ? pluMatch.variantOptions.find(
                                variantOption =>
                                    variantOption.code === product.code
                            )
                          : product.code === pluMatch.code
                  );
              } else {
                  fullProduct = pdpProductJson.find(
                      (product: Product) => product.code === plu
                  );
              }

              /**
               * The expectation is 'fullProduct' is ALWAYS populated here.
               * Would require PDP Product Call to not return data for it to be undefined.
               * Which we have not ever seen happen.
               */
              return !fullProduct
                  ? {
                        isAvailable: false,
                        itemName: '',
                        modifiers: [],
                        plu: '',
                        price: 0,
                        qty: item.qty
                    }
                  : {
                        groupCode: item.groupCode,
                        isAvailable, // alternative way: isAvailable: isProductValid(fullProduct)
                        itemName: fullProduct.name,
                        modifiers: getModifierDetails(
                            fullProduct,
                            item.modifiers,
                            onTheSideLabel
                        ),
                        plu,
                        price: itemPrice,
                        qty: item.qty
                    };
          })
        : [];

/**
 * Get extended information about selected modifiers in order to
 * display modifier names, types, and calculate price.
 * @param product the base product
 * @param modifiersArr a list of basic modifier information
 * @param onTheSideLabel template data
 * @returns CSCModifierDetail[]
 */
export const getModifierDetails = (
    product: Product | undefined,
    modifiersArr: CSCItemModifier[] = [],
    onTheSideLabel?: string
): CSCModifierDetail[] =>
    product
        ? modifiersArr
              .map((cartItemModifier: CSCItemModifier) => {
                  const modifierChild:
                      | CustomizationOption[]
                      | StyleOption
                      | string[]
                      | any = getCustomizationOptions(
                      product.customizationOptions,
                      cartItemModifier.opt
                  );
                  const variantOptionsProp = 'variantOptions';
                  let summaryModifier: CSCModifierDetail = {
                      includedWithStyle: false,
                      modifierType: CSCItemModifierType.ADD,
                      name: '',
                      opt: cartItemModifier.opt,
                      ots: cartItemModifier.ots,
                      plu: cartItemModifier?.plu || '',
                      price: 0
                  };

                  if (summaryModifier?.opt?.includes('Product')) {
                      const matchedModifier = getVariant(
                          modifierChild,
                          CSCItemModifierType.ADD
                      );
                      summaryModifier = {
                          includedWithStyle: false,
                          modifierType:
                              summaryModifier.opt ===
                              CSCItemModifierOptions.GRILLABLE_PRODUCT
                                  ? CSCItemModifierType.ADD
                                  : CSCItemModifierType.STYLE,
                          name: modifierChild.description,
                          opt: cartItemModifier.opt,
                          ots: cartItemModifier.ots,
                          plu: cartItemModifier.plu,
                          price: matchedModifier?.priceData?.value || 0
                      };
                  }
                  if (Array.isArray(modifierChild)) {
                      modifierChild.forEach(
                          (
                              fullProductModifier:
                                  | CustomizationOption[]
                                  | StyleOption
                                  | string[]
                          ) => {
                              /**
                               * Ensure variant options exist on modifier
                               */
                              if (variantOptionsProp in fullProductModifier) {
                                  /**
                                   * check if the full product modifier matches the cart product modifier
                                   */
                                  const matchedModifier = (fullProductModifier as StyleOption).variantOptions?.find(
                                      (variantOption: VariantOption) =>
                                          cartItemModifier.ots
                                              ? cartItemModifier.plu ===
                                                variantOption.onTheSide
                                              : cartItemModifier.plu ===
                                                variantOption.code
                                  );
                                  if (matchedModifier) {
                                      const modType =
                                          matchedModifier.modifierType;
                                      const suffix = getModifierSuffix(
                                          modType,
                                          cartItemModifier.ots,
                                          onTheSideLabel
                                      );

                                      summaryModifier = {
                                          includedWithStyle:
                                              cartItemModifier.includedWithStyle,
                                          modifierType: modType,
                                          name: matchedModifier.name + suffix,
                                          opt: cartItemModifier.opt,
                                          ots: cartItemModifier.ots,
                                          plu:
                                              cartItemModifier.ots &&
                                              matchedModifier.onTheSide
                                                  ? matchedModifier.onTheSide
                                                  : matchedModifier.code,
                                          price:
                                              matchedModifier.priceData
                                                  ?.value || 0
                                      };
                                  }
                              }
                          }
                      );
                  }
                  return summaryModifier;
              })
              .filter((m: CSCModifierDetail) => m?.name && m.name.length > 0)
        : [];

/**
 * Determine if a product is available for purchase.
 * @param product a base product
 * @returns boolean
 */
export const isProductValid = (product: Product) =>
    !!(
        product.isAvailableInStore &&
        product.purchasable &&
        !product.isPassRedemptionItem &&
        !product.isDigitalProduct
    );

/**
 * Splits any paragraphs in a given product description since closing tags don't exist.
 */
export const splitProductDescription = (description: string) =>
    description.split('<p>');
