import LazyLoad from 'react-lazyload';

import Image, { ImageProps } from '@tb-core/components/styled/image';

export interface LazyLoadImageProps extends ImageProps {
    height?: string;
    overflow?: boolean;
}

const LazyLoadImage = ({
    height = '100%',
    overflow = false,
    src = null,
    ...props
}: LazyLoadImageProps) => (
    <LazyLoad
        height={height}
        overflow={overflow}
        scrollContainer={'#scrollContainer'}
    >
        <Image {...props} src={src} />
    </LazyLoad>
);

export default LazyLoadImage;
